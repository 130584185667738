import { getDefaultProvider } from "ethers";
import WalletConnectProvider from "@walletconnect/web3-provider";
require("dotenv").config();
const { Mainnet } = require("@usedapp/core");

export const NETWORK = process.env.REACT_APP_NETWORK;

export const apiUrl = process.env.REACT_APP_API_URL;

export const getContract = () => {
  switch (NETWORK) {
    case "mainnet":
      return "0xD08aFED45F59117693218199108ddfE84C0A92bA";
    default:
      return "0x80FA71735642A7D9FADfa03F6401c3f62Ef40AfE";
  }
};

export const getChain = () => {
  switch (NETWORK) {
    case "mainnet":
      return {
        id: Mainnet.chainId,
        name: NETWORK,
      };
    case "goerli":
      return {
        id: 5,
        name: NETWORK,
      };
    default:
      return {
        id: 4, // Rinkeby
        name: NETWORK,
      };
  }
};

export const dAppsConfig = {
  readOnlyChainId: getChain().id,
  readOnlyUrls: {
    [getChain().id]: getDefaultProvider(getChain().name),
  },
};

export const infuraId = process.env.REACT_APP_INFURA_ID;

export const providerOptions = {
  injected: {
    display: {
      name: "Metamask",
      description: "Connect with the provider in your Browser",
    },
    package: null,
  },
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      bridge: "https://bridge.walletconnect.org",
      infuraId: infuraId,
    },
  },
};

export const ADMIN = [
  "0x37b057129F8f315d2F4293d11Cf0E1Cd580a5668",
  "0x48698Ef6217ecf81389928c77BC65e6eF53Fd93f",
  "0xB421EfAF07AcaeE4f90913E6935AD2dEdd25f4b3",
];

export const getAcceptTokens = () => {
  switch (NETWORK) {
    case "mainnet":
      return [
        {
          name: "USDT",
          contract: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
          dp: 6,
        },
        {
          name: "USDC",
          contract: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
          dp: 6,
        },
      ];
    default:
      return [
        {
          name: "FAU",
          contract: "0xBA62BCfcAaFc6622853cca2BE6Ac7d845BC0f2Dc",
          dp: 18,
        },
        {
          name: "WEENUS",
          contract: "0xaFF4481D10270F50f203E0763e2597776068CBc5",
          dp: 18,
        },
      ];
  }
};

export const RECEIVE_ADDRESS =
  NETWORK === "mainnet"
    ? process.env.REACT_APP_SAFE
    : "0xB421EfAF07AcaeE4f90913E6935AD2dEdd25f4b3";

export const STRIPE_CONFIG = {
  pk: process.env.REACT_APP_STRIPE_PK || "",
};

export const BASE_URL =
  process.env.REACT_APP_BASE_URL || "http://localhost:3000";
